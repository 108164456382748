import React from 'react';
import { Link } from 'react-router-dom';
import './Home.scss';

const Home = () => {
	const gameInfo = [{
		title: 'La Vie En Rose',
		path: 'aia-minigame-valentines-day',
	},
	{
		title: 'Grow Your Plant',
		path: 'aia-minigame-grow-your-plant',
	},
	{
		title: 'Earth Hour',
		path: 'aia-minigame-earth-hour',
	},
	{
		title: 'Super Smash',
		path: 'aia-minigame-hide-and-seek',
	},
	];

	return (
		<div className="home">
			<div className="home-wrapper">
				{gameInfo.map((game: any) => (
					<div className="app">
						<Link style={{ backgroundImage: `url(/assets/${game.path}_icon.png)` }} to={`/${game.path}`} />
						<p>{game.title}</p>
					</div>
				))}
			</div>
		</div>
	);
};
export default Home;

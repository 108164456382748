import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from '../pages/Home/Home';
import Game from '../pages/Game/Game';

export default () => (
	<Router>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/aia-minigame-valentines-day" component={Game} />
			<Route exact path="/aia-minigame-grow-your-plant" component={Game} />
			<Route exact path="/aia-minigame-earth-hour" component={Game} />
			<Route exact path="/aia-minigame-hide-and-seek" component={Game} />
		</Switch>
	</Router>
);

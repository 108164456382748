import React from 'react';
import './Game.scss';

const Game = (props: any) => {
	const { match: { path } } = props;

	const gameInfo: any = {
		'aia-minigame-valentines-day': 'https://pensive-blackwell-3f21b4.netlify.app/',
		'aia-minigame-grow-your-plant': 'https://eloquent-brown-556fea.netlify.app/',
		'aia-minigame-earth-hour': 'https://eager-northcutt-454bd0.netlify.app/',
		'aia-minigame-hide-and-seek': 'https://elastic-gates-86dc44.netlify.app/',
	};

	if (!path) {
		return null;
	}

	return (
		<>
			<img className="game-bg" src={`/assets/${path.replace('/', '')}.png`} alt="bg" />
			<div className="showcase">
				<iframe className="game" title={path} src={gameInfo[path.replace('/', '')]} />
			</div>
		</>
	);
};

export default Game;
